import {ErrorMessage, Field, Form, Formik} from 'formik'
import {KTSVG} from '../../../../_metronic/helpers'
import {boolean, object, string} from 'yup'
import FormikErrorComponent from '../shared/FormikErrorComponent'
import CustomCheckbox from '../shared/CustomCheckbox'
import {useForm} from '@formspree/react'
import {toast} from 'react-toastify'
import closeModal from '../../../helpers/closeModal'

const availableCities = ['Hyderabad']
const availableLocalities = ['Gachibowli', 'Sherlingampalli']
const availableDurationsOfStay = ['Less than 3 months', '3-6 months', 'More than 6 months']

export default function RequestCallModal() {
  const [state, handleSubmit] = useForm('xaygeevd')
  return (
    <div className='modal fade' tabIndex={-1} id='request_call_modal'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Request a Call</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phone: '',
              occupation: '',
              city: '',
              locality: '',
              durationOfStay: '',
              terms: false,
            }}
            validationSchema={object().shape({
              firstName: string().required('First Name is required'),
              lastName: string().required('Last Name is required'),
              phone: string().required('Phone is required'),
              occupation: string().required('Occupancy is required'),
              city: string().required('City is required').oneOf(availableCities, 'Invalid city'),
              locality: string()
                .required('Locality is required')
                .oneOf(availableLocalities, 'Invalid locality'),
              durationOfStay: string()
                .required('Duration of stay is required')
                .oneOf(availableDurationsOfStay, 'Invalid duration of stay'),
              terms: boolean().isTrue('Terms is required'),
            })}
            onSubmit={async (values, {resetForm}) => {
              console.log(values)
              try {
                await handleSubmit(values)
                toast.success('Request sent successfully')
                resetForm()
                closeModal('request_call_modal')
              } catch (error) {
                toast.error('Something went wrong')
              }
            }}
          >
            <Form>
              <div className='modal-body flex flex-col gap-5'>
                <div className='flex justify-between gap-3'>
                  <span>
                    <Field
                      name='firstName'
                      type='text'
                      placeholder='First Name'
                      className='form-control'
                    />
                    <ErrorMessage name='firstName' component={FormikErrorComponent} />
                  </span>
                  <span>
                    <Field
                      name='lastName'
                      type='text'
                      placeholder='Last Name'
                      className='form-control'
                    />
                    <ErrorMessage name='lastName' component={FormikErrorComponent} />
                  </span>
                </div>
                <span>
                  <Field
                    name='phone'
                    type='phone'
                    placeholder='Mobile Number'
                    className='form-control'
                  />
                  <ErrorMessage name='phone' component={FormikErrorComponent} />
                </span>
                <span>
                  <span className='lead fw-normal'>I am a</span>
                  <br />
                  <div
                    role='group'
                    aria-labelledby='my-radio-group'
                    className='flex items-center gap-5'
                  >
                    {['student', 'professional'].map((value) => (
                      <label key={value} className='text-capitalize lead flex items-center gap-3'>
                        <Field
                          type='radio'
                          name='occupation'
                          value={value}
                          className='form-check-input'
                        />
                        {value}
                      </label>
                    ))}
                  </div>
                  <ErrorMessage name='occupation' component={FormikErrorComponent} />
                </span>
                <div className='flex gap-3'>
                  <span className='w-full'>
                    <Field as='select' name='city' className='form-select'>
                      <option value='' disabled>
                        Select City
                      </option>
                      {availableCities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name='city' component={FormikErrorComponent} />
                  </span>
                  <span className='w-full'>
                    <Field as='select' name='locality' className='form-select'>
                      <option value='' disabled>
                        Select Locality
                      </option>
                      {availableLocalities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name='locality' component={FormikErrorComponent} />
                  </span>
                </div>
                <span>
                  <Field as='select' name='durationOfStay' className='form-select'>
                    <option value='' disabled>
                      Duration of your stay
                    </option>
                    {availableDurationsOfStay.map((durations) => (
                      <option key={durations} value={durations}>
                        {durations}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='durationOfStay' component={FormikErrorComponent} />
                </span>
                <span>
                  <div className='lead fw-normal flex items-start gap-3'>
                    <Field type='checkbox' name='terms' as={CustomCheckbox} />
                    <span>
                      By clicking on the button, I agree to the{' '}
                      <span className='text-success fw-bold'>Terms & Conditions</span>
                    </span>
                  </div>
                  <ErrorMessage name='terms' component={FormikErrorComponent} />
                </span>
              </div>
              <div className='modal-footer'>
                <button type='submit' className='btn btn-success w-100'>
                  Save changes
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  )
}
