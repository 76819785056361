/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {KTSVG} from '../../../helpers'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <div
      className='text-white py-56'
      style={{
        backgroundImage: 'url(/media/misc/footer-bg.png)',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <div className='grid grid-cols-2 lg:grid-cols-5 gap-20 max-w-screen-xl mx-auto p-5'>
        <div className='col-span-2'>
          <div className='mb-5 w-[100px] lg:w-[300px] bg-secondary p-5 rounded'>
            <img src='/logo.png' alt='EliteLiveIn' />
          </div>
        </div>
        <div>
          <h3 className='fw-bold text-white mb-10'>Our Branches</h3>
          <ul className='p-0 flex flex-col gap-5'>
            <li>Madhapur Hitech City</li>
            <li>Gachibowli Serilingampally</li>
          </ul>
        </div>
        <div>
          <h3 className='fw-bold text-white mb-10'>More</h3>
          <ul className='p-0 flex flex-col gap-5'>
            <li>
              <a href='#faq' className='text-white'>
                FAQ
              </a>
            </li>
            <li>Documentation</li>
            <li>Terms and Conditions</li>
            <li>House Rules</li>
          </ul>
        </div>
        <div>
          <h3 className='fw-bold text-white mb-10'>Stay Connected</h3>
          <ul className='p-0 flex flex-col gap-5'>
            <li>
              <a
                href='https://www.facebook.com/profile.php?id=100094889305643&mibextid=9R9pXO'
                target='blank'
                rel='noreferrer'
                className='flex items-center gap-3 text-white'
              >
                <KTSVG path='/media/icons/custom/facebook.svg' className='' />
                Facebook
              </a>
            </li>
            <li>
              <a
                href='https://instagram.com/eliteliving0106?igshid=NjIwNzIyMDk2Mg=='
                target='blank'
                rel='noreferrer'
                className='flex items-center gap-3 text-white'
              >
                <KTSVG path='/media/icons/custom/instagram.svg' className='' />
                Instagram
              </a>
            </li>
            {/* <li>
              <div className='flex items-center gap-3'>
                <KTSVG path='/media/icons/custom/youtube.svg' className='' />
                YouTube
              </div>
            </li> */}
          </ul>
        </div>
      </div>
      {/* <img src='/media/misc/footer-bg.png' className='invisible' alt='' /> */}
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
