import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {object, string} from 'yup'
import closeModal from '../../../helpers/closeModal'
import {useForm} from '@formspree/react'
import FormikErrorComponent from './FormikErrorComponent'
import {toast} from 'react-toastify'

export default function NameAndPhoneModal() {
  const [state, handleSubmit] = useForm('xaygeevd')
  return (
    <div className='modal fade' tabIndex={-1} id='name_and_phone_modal'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>EliteLiving Enquiry</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>
          <div className='modal-body'>
            <Formik
              initialValues={{name: '', phone: ''}}
              validationSchema={object().shape({
                name: string().required('Name is required'),
                phone: string().required('Phone is required'),
              })}
              onSubmit={async (values, {resetForm}) => {
                try {
                  await handleSubmit(values)
                  resetForm()
                  closeModal('contact_us_modal')
                  toast.success('Enquiry sent successfully')
                  console.log(JSON.stringify(state))
                } catch (error: any) {
                  toast.error(error?.message)
                }
              }}
            >
              <Form>
                <div className='flex flex-col gap-5'>
                  <div className='flex justify-between items-center'>
                    <Field type='text' name='name' placeholder='Name' className='form-control' />
                    <ErrorMessage name='name' component={FormikErrorComponent} />
                  </div>
                  <div className='flex justify-between items-center'>
                    <Field type='phone' name='phone' placeholder='Phone' className='form-control' />
                    <ErrorMessage name='phone' component={FormikErrorComponent} />
                  </div>
                  <button type='submit' className='btn btn-success'>
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
