import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {object, string} from 'yup'
import FormikErrorComponent from '../shared/FormikErrorComponent'
import {useForm} from '@formspree/react'
import closeModal from '../../../helpers/closeModal'

export default function ContactUsModal() {
  return (
    <div className='modal fade' tabIndex={-1} id='contact_us_modal'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Contact Us</h5>
            <button
              className='btn btn-icon btn-sm btn-active-light ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x svg-icon-success'
              />
            </button>
          </div>
          <div className='modal-body '>
            <div className='text-xl mb-5'>
              <p>
                <strong>Phone: </strong>
                <i>+917899966600</i>
              </p>
              <p>
                <strong>Email: </strong>
                <i>Enquiry@elitelivein.com</i>
              </p>
              <p>
                <strong>Support: </strong>
                <i>Support@elitelivein.com</i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
