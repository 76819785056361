import {useState} from 'react'

const FloatingSocial = () => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <div className='sticky bottom-52 z-50'>
      <a
        href='https://wa.me/7899966600'
        target='_blank'
        rel='noreferrer'
        className='absolute bottom-0 right-5 p-3 bg-[#00C750] hover:bg-[#113E51] rounded-full flex gap-5 items-center transition-all duration-150 ease-out hover:ease-in cursor-pointer max-w-fit scale-75 md:scale-100'
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <img src='/media/icons/custom/contact-us.png' className='' alt='contact us' />
        {open && <h3 className='fw-bold text-white m-0 me-5'>Contact Us</h3>}
      </a>
    </div>
  )
}

export default FloatingSocial
