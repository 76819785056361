function closeModal(id: string): void {
  const modal = document.getElementById(id)
  const closeButton = document.createElement('button')
  closeButton.setAttribute('data-bs-toggle', 'modal')
  closeButton.setAttribute('data-bs-dismiss', '#kt_modal_add_user')
  modal?.appendChild(closeButton)
  closeButton.click()
  modal?.removeChild(closeButton)
  return
}

export default closeModal
