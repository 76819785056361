import {InputHTMLAttributes} from 'react'

interface CustomSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
}
export default function CustomCheckbox({label, ...rest}: CustomSwitchProps) {
  return (
    <div className='form-check form-check-custom form-check-solid form-check-success'>
      <label className='form-check-label' htmlFor='flexCheckDefault'>
        {label}
      </label>
      <input className='form-check-input' type='checkbox' id='flexCheckDefault' {...rest} />
    </div>
  )
}
