import {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import FloatingSocial from './components/custom/FloatingSocial'
import {ScrollTop} from './components/scroll-top'
import NameAndPhoneModal from '../../app/pages/customComponents/shared/NameAndPhoneModal'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  useEffect(() => {
    const hash = location.hash
    if (hash === '#name-and-phone') {
      const nameAndPhoneModalTrigger = document.createElement('button')
      nameAndPhoneModalTrigger.setAttribute('data-bs-toggle', 'modal')
      nameAndPhoneModalTrigger.setAttribute('data-bs-target', '#name_and_phone_modal')
      document.body.appendChild(nameAndPhoneModalTrigger)
      nameAndPhoneModalTrigger.click()
      document.body.removeChild(nameAndPhoneModalTrigger)
    }
  }, [])

  return (
    <PageDataProvider>
      {/* <ThemeModeProvider> */}
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid bg-white' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid relative' id='kt_app_wrapper'>
            {/* <Sidebar /> */}
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {/* <ToolbarWrapper /> */}
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
              <FloatingSocial />
              <NameAndPhoneModal />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      {/* <ActivityDrawer /> */}
      {/* <RightToolbar /> */}
      {/* <DrawerMessenger /> */}
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      {/* <InviteUsers /> */}
      {/* <UpgradePlan /> */}
      {/* end:: Modals */}
      {/* <ScrollTop /> */}
      {/* </ThemeModeProvider> */}
    </PageDataProvider>
  )
}

export {MasterLayout}
