import {FC, createContext, useContext, useEffect, useState} from 'react'
import {WithChildren} from '../../../_metronic/helpers'

export interface Hostel {
  _id: number
  active: boolean
  name: string
  location: string
  hostel_type: string
  sharing_type: Array<{name: string; cost: number}>
  amenities: Array<string>
  images: Array<string>
  starting_price: number
  map: string
}

interface HostelContextProps {
  hostels: Hostel[]
}

const initialValues: HostelContextProps = {
  hostels: [],
}

const HostelContext = createContext<HostelContextProps>(initialValues)

let refetch = false

const useHostels = () => {
  refetch = !refetch
  return useContext(HostelContext)
}

const HostelProvider: FC<WithChildren> = ({children}) => {
  const [hostelContextValue, setHostelContextValue] = useState<HostelContextProps>(initialValues)
  useEffect(() => {
    ;(async () => {
      const res = await fetch('data.json')
      const data = await res.json()
      setHostelContextValue({...hostelContextValue, hostels: data})
    })()
  }, [refetch])
  return <HostelContext.Provider value={hostelContextValue}>{children}</HostelContext.Provider>
}

export {HostelProvider, useHostels}
