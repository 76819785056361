// const itemClass = 'ms-1 ms-lg-3'
// const btnClass =
//   'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
// const userAvatarClass = 'symbol-35px symbol-md-40px'
// const btnIconClass = 'fs-1'

import {useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import Dropdown from 'react-bootstrap/Dropdown'
import RequestCallModal from '../../../../app/pages/customComponents/Navbar/RequestCallModal'
import ContactUsModal from '../../../../app/pages/customComponents/Navbar/ContactUsModal'

const Navbar = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full flex justify-between items-center gap-5 flex-wrap container-fluid'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTIcon iconName='chart-simple' className={btnIconClass} />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTIcon iconName='element-plus' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTIcon iconName='message-text-2' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnclassName={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div> */}

      {/* {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )} */}
      <div>
        <div className='cursor-pointer w-[75px] lg:w-[100px]' onClick={() => navigate('/')}>
          <img src='/logo.png' alt='ElitiLiveIn' />
        </div>
      </div>
      <div className='hidden lg:flex items-stretch border border-success rounded '>
        <div className='p-3 border-end border-success flex items-center'>
          <Dropdown>
            <Dropdown.Toggle variant='' id='dropdown-basic' className='btn-sm'>
              Choose Property Type
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1'>Unisex</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='p-3 flex items-center gap-5'>
          <Dropdown>
            <Dropdown.Toggle variant='' id='dropdown-basic' className='btn-sm'>
              Locations
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href='#/action-1'>Hyderabad</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button className='btn btn-sm btn-icon btn-success'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
          </button>
        </div>
      </div>
      <div className='flex gap-5'>
        <button
          className='btn btn-success d-none d-lg-inline-block'
          data-bs-toggle='modal'
          data-bs-target='#request_call_modal'
        >
          <div className='flex items-center gap-5'>
            <span>Request a Call </span>
            <KTSVG
              path='/media/icons/custom/phone.svg'
              className='bg-white p-2 rounded svg-icon-1x'
            />
          </div>
        </button>
        <button
          className='btn btn-success btn-icon d-lg-none'
          data-bs-toggle='modal'
          data-bs-target='#request_call_modal'
        >
          <KTSVG path='/media/icons/custom/phone-light.svg' className='p-2 rounded svg-icon-2x' />
        </button>
        <RequestCallModal />
        <button
          className='btn btn-outline btn-outline-success bg-white custom-skeleton-btn d-none d-lg-inline-block '
          data-bs-toggle='modal'
          data-bs-target='#contact_us_modal'
        >
          <div className='flex items-center gap-5'>
            <span>Contact Us</span>
            <KTSVG path='/media/icons/custom/phone.svg' className='p-2 rounded svg-icon-1x' />
          </div>
        </button>
        <a
          href='tel:7899966600'
          className='btn btn-icon btn-outline btn-outline-success bg-white custom-skeleton-btn d-lg-none'
        >
          <div className='flex items-center gap-5'>
            <KTSVG path='/media/icons/custom/phone.svg' className='p-2 rounded svg-icon-2x' />
          </div>
        </a>
        <ContactUsModal />
      </div>
    </div>
  )
}

export {Navbar}
