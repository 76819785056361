import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {HostelProvider} from './pages/customContexts/HostelContext'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <HostelProvider>
              <Outlet />
              <MasterInit />
            </HostelProvider>
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer />
    </Suspense>
  )
}

export {App}
